import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ComingSoonPage = () => (
  <>
    <Seo title="404: Not found" />
    <section className={`fix-header`}>
      <div className={`content`}>
        <h1>Page Coming Soon</h1>
        <p>If you're seeing this, the page isn't ready yet but will be within 2-3 days.</p>
        <Link to={`/`}><button>Explore from Home</button></Link>
      </div>
    </section>
  </>
)

export default ComingSoonPage